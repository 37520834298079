import React from "react"
import ListHoney from "src/components/Pages/ListHoney/ListHoney"
import {graphql} from "gatsby";
import PropTypes from "prop-types";

const OffertPage = ({data}) =>{
    const Offert = data.allDatoCmsOffer.nodes;

    return(
        <>
            <ListHoney Data={Offert} title="Miody tradycyjne"/>
        </>
    )
}

export default OffertPage

OffertPage.propTypes = {
    data: PropTypes.object,
};

export const query = graphql`
    query {
        allDatoCmsOffer(filter: {category: {eq: "miody_tradycyjne"}}, sort: {fields: title}) {
            nodes {
                id
                title
                slug
                description
                price
                category
                capacity {
                    choicecapacity
                }

                fotoOffer {

                    gatsbyImageData(
                        placeholder: BLURRED
                        forceBlurhash: false
                        layout:CONSTRAINED
                    )

                }
            }
        }
    }


`;
